<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })
const router = useRouter()
const route = useRoute()

const handleSubmit = () => {
  router.push('/manse')
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">낭만 저장소</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>구독</li>
          <li>낭만 저장소</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="finish-box">
        <div class="com-btn-box"><i class="icon-check"></i></div>
        <div class="gap-xxl"></div>
        <div class="fnt-big">저장소 이용권 신청이 실패되었습니다!</div>
        <div class="gap-xxl"></div>
        <div class="com-btn-box">
          <VBtn type="submit" style="width: 100%" @click="handleSubmit"> 만세력 이용하기 </VBtn>
        </div>
        <div class="gap-xxl"></div>
        <div class="">
          신청이 완료된 내역은 마이페이지에서 확인하실 수 있으며, 환불은 신청 후 1일 이내에
          가능합니다. <button class="btn-txt-line">환불정책 바로가기</button>
        </div>
      </div>
    </div>
  </div>
</template>

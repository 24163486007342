<script setup>
import fiveMixin from '@/composables/fiveMixin';
import useFunctions from '@/composables/useFunctions';

const { ...five } = fiveMixin();
const { ...useFun } = useFunctions();
const props = defineProps({
  prItem: {
    type: Object,
    default: null,
  },
  storedLangMode: {
    type: String,
    default: 'CHNKOR',
  }
});
const currentYear = ref(new Date().getFullYear());
const isActiveYear = (year) => {
  return year === currentYear.value;
};
onMounted(() => {

})

const showHanja = (value) => {
  if (!value) {
    return '';
  }
  return props.storedLangMode === 'CHNKOR' ? five.getHanjaTxt2(value) : value;
}
</script>
<template>
  <div>
    <div
      v-for="(item, index) in prItem"
      :key="index"
      class="pt-row-box"
      :class="{'half-line': index % 2 === 1}"
    >
      <div class="tit">
        <span
        v-if="item.value.length > 1"
          class="txt1 ganji-txt"
          :class="[five.getColorFive(item?.daeun)]"
        >
        {{ showHanja(item.daeun) }}
        <!-- {{ item.daeun }} -->
      </span>
        <span 
        v-if="item.value.length > 1"
        class="txt2">
        {{ item.daeunsix }}
      </span>
      </div>
      <div
        v-for="(item2, index2) in item.value"
        :key="index2"
        class="text-box"
      >
        <div :class="['year', { active: isActiveYear(item2.year) }]">
          {{ item2.year }}
        </div>
        <div
          :class="['txt1', { active: isActiveYear(item2.year) }]"
        >
          <span
            v-if="isActiveYear(item2.year)"
            class="txt1-sub"
          >
            <span :class="[ 'ganji-txt', five.getColorFive(item2.yearju[0])]">{{ showHanja(item2.yearju[0]) }}</span>
            <span :class="[ 'ganji-txt', five.getColorFive(item2.yearju[1])]">{{ showHanja(item2.yearju[1]) }}</span>
          </span>
          <span
            v-else
            class="txt1-sub"
          >
            <span>{{ showHanja(item2.yearju[0]) }}</span>
            <span>{{ showHanja(item2.yearju[1]) }}</span>
          </span>
        </div>
        <div
          :class="['txt2', { active: isActiveYear(item2.year) }]"
        >
          {{ item2.six }}
        </div>
        <div
          :class="['no', { active: isActiveYear(item2.year) }]"
        >
          {{ item2.age }}
        </div>
      </div>
    </div>
  </div>
</template>
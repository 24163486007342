<script setup>
definePage({ meta: { layout: 'auth' } })
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
const route = useRoute()
const router = useRouter()
const store = useStore()

const onClickConfirm = async () => {
  router.push('/manse')
}
</script>
<template>
  <div class="inner st-callback">
    <div class="content">
      <div class="step-indicator">
        <div class="step-complete">
          <img src="../../assets/images_manse/check_circle.png" alt="Logo" width="80" height="80" />
          <img src="../../assets/images_manse/logo_b.png" alt="Logo" width="140" height="53" />
        </div>
      </div>
      <div class="form-title text-center">회원이 되신 것을 환영합니다.</div>
      <div class="form-description">
        이용권을 구독하시면 더욱 편리하게<br />만세력 이용을 하실 수 있습니다.
      </div>

      <VBtn block type="submit" class="h-12 login-button" @click="onClickConfirm"
        >만세력 이용하기</VBtn
      >
    </div>
  </div>
</template>

<style lang="scss">
//@use "@nstyles/temporary.scss";

.empty-page {
  width: 100%;
  height: 100vh;
  text-align: center;
  font-size: 30px;
}
.st-callback {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

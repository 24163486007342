<script setup>
import { DEV_MODE } from '@/main'
import { VForm } from 'vuetify/components/VForm'
import { VTextField } from 'vuetify/lib/components/index.mjs'
import { useStore } from 'vuex'

definePage({ meta: { layout: 'login' } })

const refVForm = ref()
const store = useStore()
const route = useRoute()
const router = useRouter()

const show1 = ref(false)

const rules1 = {
  required: (value) => !!value || '아이디를 입력해주세요.',
  //min: (v) => v.length >= 4 || '4자리 이상 ',
}
const rules2 = {
  required: (value) => !!value || '비밀번호를 입력해주세요.',
  //min: (v) => v.length >= 4 || '4자리 이상 ',
}
const credentials = ref({
  loginId: '',
  loginPw: '',
})

const errors = ref({
  loginId: undefined,
  loginPw: undefined,
})

const onSubmit = () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid) login()
  })
}
const login = async () => {
  const result = await store.dispatch('user/login', {
    loginId: credentials.value.loginId,
    loginPw: credentials.value.loginPw,
  })
  if (store.state.user.errorMsg) {
    alert(store.state.user.errorMsg)
    return
  }
  await nextTick(() => router.replace(route.query.to ? String(route.query.to) : '/manse'))
}

const isSignInDisabled = computed(() => {
  return credentials.value.loginId.length < 3 || credentials.value.loginPw.length < 3
})

const SNSlogin_blank = (provider) => {
  const apiURL = import.meta.env.VITE_APP_GW_URL
  // 새 창에서 해당 제공자의 로그인 URL 열기
  window.open(`${apiURL}/oauth/login/${provider}`, '_self')
}
</script>

<template>
  <div class="login-content">
    <RouterLink to="/main">
      <div class="login-logo" />
    </RouterLink>

    <VRow no-gutters class="auth-wrapper">
      <VCol
        cols="12"
        class="auth-card-v2 d-flex align-center justify-center login-inner-box"
        style="background-color: rgb(var(--v-theme-surface))"
      >
        <VCard flat class="mt-12 mt-sm-0 pa-0 login-inner-box">
          <VCardText class="login-text-box">
            <div class="">
              {{ memberName }}
            </div>
            <div class="login-tit-box">만세력과 함께 여정을 떠나볼까요 ✨</div>
            <div class="join-txt-box">처음이신가요? <a href="/auth/join">회원가입 하기</a></div>
          </VCardText>

          <VCardText>
            <VForm ref="refVForm" @submit.prevent="onSubmit">
              <VRow class="login-form">
                <!-- email -->
                <VCol cols="12">
                  <VTextField
                    v-model="credentials.loginId"
                    autofocus
                    placeholder="아이디"
                    type="text"
                    :rules="[rules1.required]"
                    :error-messages="errors.loginId"
                    style="margin-top: 12px"
                  />
                </VCol>

                <!-- password -->
                <VCol cols="12">
                  <VTextField
                    v-model="credentials.loginPw"
                    placeholder="비밀번호"
                    :rules="[rules2.required]"
                    :type="show1 ? 'text' : 'password'"
                    :error-messages="errors.password"
                    :append-inner-icon="show1 ? 'ri-eye-off-line' : 'ri-eye-line'"
                    @click:append-inner="show1 = !show1"
                  />
                  <div class="flex my-5 gap-2"></div>
                  <!-- <div class="d-flex align-center flex-wrap justify-space-between my-5 gap-2">
                    <VCheckbox label="로그인 상태 유지" />
                  </div> -->
                  <VBtn block :disabled="isSignInDisabled" type="submit" class="h-12 login-button">
                    로그인
                  </VBtn>
                </VCol>
              </VRow>
            </VForm>
          </VCardText>

          <!-- auth providers -->
          <VCol cols="12" class="auth-providers">
            <div class="find-box">
              <button class="btn-nor" @click="() => router.push('/auth/find-id')">
                아이디 찾기
              </button>
              <button class="btn-nor" @click="() => router.push('/auth/reset')">
                비밀번호 재설정
              </button>
            </div>

            <div class="btn-sns-box">
              <button class="icon-kko btn-rd" @click="SNSlogin_blank('kakao')">카카오</button>
              <button class="icon-naver btn-rd" @click="SNSlogin_blank('naver')">네이버</button>
              <button class="icon-google btn-rd" @click="SNSlogin_blank('google')">구글</button>
              <button class="icon-apple btn-rd" @click="SNSlogin_blank('apple')">애플</button>
            </div>
          </VCol>
        </VCard>
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss">
@use '@core/scss/template/pages/page-auth.scss';
//@use "@nstyles/auth.scss";
</style>

<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { orderConfirm } from '@/api/PaymentApi'

const router = useRouter()
const route = useRoute()
const orderId = ref('')
const paymentKey = ref('')
const amount = ref('')

//TODO 빌링키 등록
const order = async () => {
  try {
    const paramSubscr = {
      orderId: orderId.value,
      paymentKey: paymentKey.value,
      amount: amount.value,
    }
    const subscrData = await orderConfirm(paramSubscr)
    console.log('이용권 결제 성공')
    console.log(subscrData)
    //router.push('/mypage/subscrFinishBillEnd')
  } catch (error) {
    console.log(error)
    //router.push('/mypage/subscrFinishMonthFail')
  }
}

//TODO 컴포넌트가 마운트될 때 getBillKey 호출
onMounted(() => {
  orderId.value = route.query.orderId
  paymentKey.value = route.query.paymentKey
  amount.value = route.query.amount
  order()
})
//mypage/subscrFinish?paymentType=NORMAL&orderId=ord_241204000022&paymentKey=tnang20241204150859pLIc8&amount=2970
</script>

<script setup>
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const props = defineProps({
  prData: {
    type: Object,
    default: null,
  },
  prIndex: {
    type: Number,
    default: 0,
  },
  prGalleryDataTitle: {
    type: Array,
    default: [],
  },
  prBirthSkyTxt: {
    type: Array,
    default: [],
  },
  prBirthEarthTxt: {
    type: Array,
    default: [],
  },
  prGalleryTitles: {
    type: Array,
    default: [],
  },
  prTaewonData: {
    type: Array,
    default: [],
  },
  prGongmangData: {
    type: Array,
    default: [],
  },
})

const skyTxt = ref(props.prBirthSkyTxt)
const earthTxt = ref(props.prBirthEarthTxt)
const galleryTitles = ref(props.prGalleryTitles)

const taewonData = ref(props.prTaewonData.map((item) => item.value).join(''))
const gongmangData = ref(props.prGongmangData.map((item) => item.value).join(''))

onMounted(() => {
  //dataSet(props.prDataAll); // 초기 데이터 설정
})

watch(
  () => props.prBirthSkyTxt,
  (newVal) => {
    skyTxt.value = newVal
  },
  { deep: true },
)

watch(
  () => props.prBirthEarthTxt,
  (newVal) => {
    earthTxt.value = newVal
  },
  { deep: true },
)
</script>
<template>
  <div class="data-detail-top">
    <span class="txt1">신살</span>
    <span class="data-none" v-if="prData.length === 0"> 신살이 없습니다. </span>
    <div class="card-top-4fr-list">
      <span
        v-for="(charItem, charIdx) in earthTxt"
        :key="charIdx"
        class="txt2 txt-round detail"
        :class="[
          five.getColorFive(earthTxt[charIdx]),
          prLangMode === 'CHNKOR' ? 'chnkor' : '',
          prData[1][prData[1]?.length - 1][valueIndex] === 0 ? 'none' : '',
        ]"
      >
        {{ charItem }}
      </span>
    </div>
    <div class="card-top-4fr-list">
      <div v-for="(group, groupIndex) in prData[1]" :key="groupIndex" class="txt3">
        <div v-for="(item, itemIndex) in group" :key="itemIndex">
          {{ item === '-' ? '' : item }}
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="detail-gap"></div>
  <div class="data-detail-flex">
    <div class="detail-box">
      <span class="txt1">공망</span>
      <div class="card-2fr-list">
        <span
          v-for="(charItem, charIdx) in gongmangData"
          :key="charIdx"
          class="txt2 txt-round detail"
          :class="[
            five.getColorFive(charItem),
            prLangMode === 'CHNKOR' ? 'chnkor' : '',
            prData[1][prData[1]?.length - 1][valueIndex] === 0 ? 'none' : '',
          ]"
        >
          {{ charItem }}
        </span>
      </div>
    </div>
    <div class="gong-div-tae" />
    <div class="detail-box">
      <span class="txt1">태월</span>
      <div class="card-2fr-list">
        <span
          v-for="(charItem, charIdx) in taewonData"
          :key="charIdx"
          class="txt2 txt-round detail"
          :class="[
            five.getColorFive(charItem),
            prLangMode === 'CHNKOR' ? 'chnkor' : '',
            prData[1][prData[1]?.length - 1][valueIndex] === 0 ? 'none' : '',
          ]"
        >
          {{ charItem }}
        </span>
      </div>
    </div>
  </div> -->
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) => ['prev', 'next', 'first', 'last'].includes(value), // 지원하는 아이콘 타입
  },
});

const iconPath = computed(() => {
  switch (props.type) {
    case 'prev':
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M4.5 10.8684C3.83333 10.4835 3.83333 9.52121 4.5 9.13631L13.5 3.94015C14.1667 3.55525 15 4.03638 15 4.80618L15 15.1985C15 15.9683 14.1667 16.4494 13.5 16.0645L4.5 10.8684Z" fill="#333333"/>
        </svg>
        `;
    case 'next':
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.5 10.8684C16.1667 10.4835 16.1667 9.52121 15.5 9.13631L6.5 3.94015C5.83333 3.55525 5 4.03638 5 4.80618L5 15.1985C5 15.9683 5.83333 16.4494 6.5 16.0645L15.5 10.8684Z" fill="#333333"/>
        </svg>
        `;
    case 'first':
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 11.1546V15.4999C5 15.776 4.77614 15.9999 4.5 15.9999C4.22386 15.9999 4 15.776 4 15.4999L4 9.99989L4 4.49989C4 4.22375 4.22386 3.99989 4.5 3.99989C4.77614 3.99989 5 4.22375 5 4.49989V8.84519L13.5 3.93771C14.1667 3.55281 15 4.03394 15 4.80374L15 15.196C15 15.9658 14.1667 16.447 13.5 16.0621L5 11.1546Z" fill="#333333"/>
        </svg> 
      `;
    case 'last':
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 11.1546V15.4999C15 15.776 15.2239 15.9999 15.5 15.9999C15.7761 15.9999 16 15.776 16 15.4999L16 9.99989L16 4.49989C16 4.22375 15.7761 3.99989 15.5 3.99989C15.2239 3.99989 15 4.22375 15 4.49989V8.84519L6.5 3.93771C5.83333 3.55281 5 4.03394 5 4.80374L5 15.196C5 15.9658 5.83333 16.447 6.5 16.0621L15 11.1546Z" fill="#333333"/>
        </svg>
      `;
    default:
      return null;
  }
});
</script>

<template>
    <span class="icon" v-html="iconPath"></span>
  </template>

<style scoped>
.icon {
  display: inline-block;
  cursor: pointer;
}
</style>

<script setup>
import IconMypage from '@/components/svg/IconMypage.vue'
import IconMySubscr from '@/components/svg/IconMySubscr.vue'
import IconMyInquiry from '@/components/svg/IconMyInquiry.vue'
import IconMyLife from '@/components/svg/IconMyLife.vue'
import IconArrow01 from '@/components/svg/IconArrow01.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AlertComponent from '@/pages/manse/components/Alert.vue'

import { DEV_MODE } from '@/main'

const route = useRoute()
const store = useStore()
const userId = ref(null)
userId.value = store.getters['user/id']

if (DEV_MODE) {
  //개발서버 일때
}

const gnbMenuItemsDev = [
  {
    name: 'My',
    link: '/mypage/myinfo',
    icon: 'icon-myinfo',
    subItems: [
      { name: '내 정보', link: '/mypage/myinfo' },
      { name: '부가 정보', link: '/mypage/moreinfo' },
    ],
  },
  {
    name: '낭만 저장소',
    link: '/mypage/subscr',
    icon: 'icon-subscr',
    subItems: [
      { name: '서비스 안내', link: '/mypage/subscr' },
      { name: '저장소 정기구독', link: '/mypage/subscrRepo' },
      { name: '월 이용권 구매', link: '/mypage/subscrRepoMonth' },
      { name: '낭만 포인트 충전', link: '/mypage/subscrPoint' },
      { name: '결제내역 / 포인트', link: '/mypage/subscrPayment' },
    ],
  },
  {
    name: '문의',
    link: '/mypage/inquiry',
    icon: 'icon-inquiry',
    subItems: [
      { name: '문의하기', link: '/mypage/inquiry' },
      { name: '문의내역', link: '/mypage/inquiryList' },
    ],
  },
  {
    name: '라이프캘린더',
    link: '/mypage/life',
    icon: 'icon-life',
    subItems: [
      { name: '등록', link: '/mypage/life' },
      { name: '보기', link: '/mypage/lifeView' },
    ],
  },
]

const gnbMenuItemsSer = [
  {
    name: 'My',
    link: '/mypage/myinfo',
    icon: 'icon-myinfo',
    subItems: [
      { name: '내 정보', link: '/mypage/myinfo' },
      { name: '부가 정보', link: '/mypage/moreinfo' },
    ],
  },
  {
    name: '문의',
    link: '/mypage/inquiry',
    icon: 'icon-inquiry',
    subItems: [
      { name: '문의하기', link: '/mypage/inquiry' },
      { name: '문의내역', link: '/mypage/inquiryList' },
    ],
  },
]

const gnbMenuItems = DEV_MODE ? gnbMenuItemsDev : gnbMenuItemsSer

//게스트 일 경우
if (!userId.value) {
  //gnbMenuItems[2].subItems[0] = { name: '문의하기', link: '/mypage/inquiryGuest' }
  gnbMenuItems.length = 0 // 기존 메뉴 항목 제거
  gnbMenuItems.push({
    name: '문의하기',
    link: '/mypage/inquiryGuest',
    icon: 'icon-inquiry',
    subItems: [],
  })
  gnbMenuItems.push({
    name: '서비스 안내',
    link: '/mypage/subscrGuest',
    icon: 'icon-subscr',
    subItems: [],
  })
}

const isActive = (link) =>
  route.path.split('/').slice(0, 3).join('/') === link.split('/').slice(0, 3).join('/')

const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogText = ref('')
const dialogNextUrl = ref('')
const nextUrl = '/auth/login'

const showDialogAlert = (title, text = '', nextUrl = '') => {
  dialogTitle.value = title
  dialogText.value = text
  dialogNextUrl.value = nextUrl
  dialogAlert.value.openAlertDialog()
}
</script>

<template>
  <div class="gnb-menu-box">
    <div v-for="(menu, index) in gnbMenuItems" :key="index" class="depth01">
      <div
        v-if="menu.name === '문의하기' && !userId"
        class="gnb-link"
        @click="showDialogAlert('로그인 후 이용 가능합니다.', nextUrl)"
      >
        <IconMyInquiry />
        <span>{{ menu.name }}</span>
      </div>
      <RouterLink v-else class="gnb-link" :to="menu.link" :class="{ active: isActive(menu.link) }">
        <IconMypage v-if="menu.icon === 'icon-myinfo'" />
        <IconMySubscr v-if="menu.icon === 'icon-subscr'" />
        <IconMyInquiry v-if="menu.icon === 'icon-inquiry'" />
        <IconMyLife v-if="menu.icon === 'icon-life'" />
        <span>{{ menu.name }}</span>
      </RouterLink>
      <div v-if="menu.subItems.length > 0" class="sub-menu">
        <div v-for="(subItem, subIndex) in menu.subItems" :key="subIndex">
          <RouterLink class="sub-link" :to="subItem.link">
            <span>{{ subItem.name }}</span>
            <i class=""><IconArrow01 /> </i>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>

  <AlertComponent
    ref="dialogAlert"
    :title="dialogTitle"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />
</template>

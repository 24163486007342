import { createStore } from 'vuex'

// Import modules
// import AppModule from "./modules/app"
import UserModule from './modules/user'
import UserManager from './modules/queries/users-manager'
import boards from './modules/boards'
// import ErrorModule from "./modules/error"
// import QueriesModule from "./modules/queries"

const store = createStore({
  modules: {
    // app: AppModule,
    user: UserModule,
    UserManager,
    boards,
    // error: ErrorModule,
    // queries: QueriesModule,
  },
  getters: {
    params: (state) => state.params,
  },
  state: {
    params: null,
    menu_roles: {},
    productId: null, //상품코드값
  },
  mutations: {
    setParams(state, payload) {
      state.params = payload
    },
    setProductId(state, value) {
      state.productId = value
    },
  },
  actions: {
    updateProductId({ commit }, value) {
      commit('setProductId', value)
    },
  },
})

export default store

<script setup>
definePage({ meta: { layout: 'auth' } })
import useJoin from '@/composables/useJoin'
import { CheckField, PopupModal, Consent } from '@/components/auth'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { refreshToken } from '@/utils/auth'
import { userAgreed } from '@/api/UserInfo'
const route = useRoute()
const router = useRouter()
const store = useStore()
const {
  terms,
  marketingConsent,
  agreeAll,
  toggleAgreeAll,
  toggleMarketingConsent,
  clickConsent,
  showPopup,
  popupMessage,
  popupButtonText,
  showConsent,
  consentHeader,
  consentContent,
  consentButtonText,
  closePopup,
  closeConsent,
} = useJoin()

const pathVal = ref('')

/** 완료 버튼 활성화 여부 */
const isConfirmDisabled = ref(false)

watch(
  () => terms.value.map((term) => term.agreed),
  (newAgreements) => {
    const allAgreed = terms.value[0]?.agreed && terms.value[1]?.agreed && terms.value[2]?.agreed

    isConfirmDisabled.value = allAgreed // 모두 동의하지 않으면 버튼 비활성화
  },
  { immediate: true }, // 컴포넌트 마운트 시 즉시 체크
)
const hashParams = new URLSearchParams(window.location.hash.substring(1))

const saveTokens = async (hashParams) => {
  const access_token = hashParams.get('access_token')
  const refresh_token = hashParams.get('refresh_token') // 주의: refresh_token이 URL에 없을 경우 null
  const id = hashParams.get('id')
  const name = hashParams.get('name')

  const data = {
    access_token: access_token,
    refresh_token: refresh_token,
    id: id,
    name: name,
  }

  // Vuex 스토어에 데이터 전송
  await store.dispatch('user/snslogin', data)
  await refreshToken(refresh_token)
}

const onClickConfirm = async () => {
  try {
    await saveTokens(hashParams)
    //01> 동의
    const params = {
      first: false,
      clause: false,
      personal: false,
      mail: false,
      push: false,
      sms: false,
    }

    const agreedTerms = terms.value.map((term, index) => {
      if (index === 0 && term.agreed) {
        params.first = true // 첫 번째 항목 체크 시 first에 true
      }
      if (index === 1 && term.agreed) {
        params.clause = true // 두 번째 항목 체크 시 clause에 true
      }
      if (index === 2 && term.agreed) {
        params.personal = true // 세 번째 항목 체크 시 personal에 true
      }
    })
    if (!params.first) {
      alert('만 14세 이상입니다 이용약관에 동의해주세요')
      return
    }
    if (!params.clause) {
      alert('서비스 이용약관에 동의해주세요')
      return
    }
    if (!params.personal) {
      alert('개인정보 이용 및 수집에 동의해주세요')
      return
    }
    const agreedMarket = marketingConsent.value.map((market, index) => {
      if (index === 0 && market.agreed) {
        params.push = true // push
      }
      if (index === 1 && market.agreed) {
        params.sms = true // sms
      }
      if (index === 1 && market.agreed) {
        params.mail = true // mail
      }
    })
    // 체크된 항목에 대해 API 호출
    const paramsAgree = {}
    let rs = null
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        paramsAgree.agreed = true
        rs = await userAgreed(paramsAgree, key)
      }
    }

    if (rs) {
      await saveTokens(hashParams)
      await router.push('/auth/finish')
    }
  } catch (error) {
    console.error('Error during agreement confirmation:', error)
    alert('동의 처리 중 오류가 발생했습니다. 다시 시도해 주세요.') // 예시로 alert 사용
  }
}

// 페이지에 접근했을 때 자동 실행
onMounted(() => {
  // const hashParams = new URLSearchParams(window.location.hash.substring(1))
  const action = hashParams.get('action')

  if (!action) {
    // action이 없을 경우 토큰을 저장하고 메인으로 이동
    saveTokens(hashParams)
    router.push('/manse')
  }
})
</script>
<template>
  <div class="inner st-callback">
    <div class="content">
      <div class="step-indicator">
        <div class="step-active">
          <div class="step">1</div>
          <div class="line"></div>
          <div class="step active">2</div>
        </div>
      </div>
      <div class="form-title text-center">마지막 단계입니다!<br />약관 내용에 동의해주세요 📋</div>
      <div class="form-description">원활한 서비스 제공을 위해 필수 항목 동의가 필요합니다.</div>

      <div class="terms-container">
        <CheckField
          v-model="agreeAll"
          :class="'terms-header'"
          icon-type="type0"
          label="전체 약관에 동의합니다."
          :toggle-checkbox="toggleAgreeAll"
        />
        <div class="terms-list">
          <CheckField
            v-for="(term, index) in terms"
            :key="index"
            v-model="term.agreed"
            :class="'terms-item'"
            :label="term.text"
            :required-indicator="term.required"
            :optional-indicator="!term.required"
            :show-view-button="index !== 0"
            :toggle-checkbox="index === 3 ? toggleMarketingConsent : () => {}"
            @view="() => clickConsent(term.content, index)"
          />
        </div>
        <div class="marketing-consent">
          <CheckField
            v-for="(item, index) in marketingConsent"
            :key="index"
            v-model="item.agreed"
            :class="'marketing-item'"
            :label="item.text"
          />
        </div>
      </div>
      <VBtn
        block
        :disabled="!isConfirmDisabled"
        type="submit"
        class="h-12 login-button"
        @click="onClickConfirm"
        >회원가입 완료!</VBtn
      >
    </div>
  </div>
  <Consent
    v-if="showConsent"
    :header="consentHeader"
    :message="consentContent"
    :button-text="consentButtonText"
    @close="closeConsent"
    @confirm="closeConsent"
  />

  <PopupModal
    v-if="showPopup"
    :message="popupMessage"
    :buttonText="popupButtonText"
    :isVisible="showPopup"
    :add-on="joinStep === 2"
    @close="closePopup"
    @confirm="confirmPopup"
  />
</template>
<style lang="scss">
//@use "@nstyles/temporary.scss";

.empty-page {
  width: 100%;
  height: 100vh;
  text-align: center;
  font-size: 30px;
}
.st-callback {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

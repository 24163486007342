import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { getToken, refreshToken, getUid } from '@/utils/auth'
import { DEV_MODE } from '@/main'
import store from '@/store'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

let xidPromise = null
const getXID = () => {
  if (!xidPromise) {
    xidPromise = (async () => {
      let xid = localStorage.getItem('xid')
      if (!xid || xid.trim() === '') {
        const fp = await FingerprintJS.load()
        const result = await fp.get()
        xid = result.visitorId
        localStorage.setItem('xid', xid)
      }
      return xid
    })()
  }
  return xidPromise
}

export function setupInterceptors(router) {
  const refresh = async (config) => {
    const access_token = getToken()
    if (access_token && jwtDecode(access_token).exp > Date.now() / 1000) {
      return axios.request(config)
    }
    try {
      const data = await refreshToken()
      sessionStorage.setItem('userInfo', JSON.stringify(data))
      sessionStorage.setItem('X-Token', data.access_token)
      sessionStorage.setItem('refresh_token', data.refresh_token)
      sessionStorage.setItem('authUserId', data.id)

      config.headers.Authorization = `Bearer ${data.access_token}`
      config.headers.authUserId = data.id
      return axios.request(config)
    } catch (error) {
      handleErrorResponse(error)
      return Promise.reject(error)
    }
  }

  axios.interceptors.request.use(
    async (config) => {
      const xIdentifier = await getXID().then((xid) => xid)
      const token = getToken()
      const uid = getUid()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
        // 추가적인 헤더 설정이 필요하면 여기에 추가
        config.headers.authUserId = uid
      }

      config.headers['X-Identifier'] = xIdentifier
      return config
    },
    (error) => Promise.reject(error),
  )

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        DEV_MODE && console.log(error.response.data)
        if (error.response.data.detail?.startsWith('Access token expired')) {
          return refresh(error.config)
        } else {
          sessionStorage.clear()
          //alert('로그인 세션이 종료되었습니다. 다시 로그인해주세요2.')
          //router.push('/auth/login')
          return
          //alert(error.response.data.error_description)
        }
      }
      handleErrorResponse(error)
      return Promise.reject(error)
    },
  )

  function handleErrorResponse(error) {
    if (
      error.response &&
      error.response.status === 401
      //&& error.response.data.error_description?.startsWith('Invalid refresh token (expired)')
    ) {
      sessionStorage.clear()
      alert('로그인 세션이 종료되었습니다. 다시 로그인해주세요.')
      router.push('/auth/login')
      return
    }
    // 추가적인 에러 처리 로직

    //router.push('/error/error01')
  }
}

export default setupInterceptors
